import React, { useEffect, useMemo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';
import * as yup from 'yup';
import { parsePhoneNumberFromString } from 'libphonenumber-js';
import { FormProvider, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';

import { Modal } from '@/modules/shared/components/modal';
import {
  MemoryRouter, NavigateFunction, Route, Routes, useLocation,
} from 'react-router-dom';
import i18n from '@/lib/i18n';
import { useRouter } from 'next/router';
import { toast } from 'react-toastify';
import { useMeQuery } from '@/modules/me/providers/me.query';
import { useCloseAuthModal, useOpenAuthModal } from '../../hooks';
import { useAuthContext } from '../../contexts';
import { Login } from './components/auth/login.component';
import { Password } from './components/auth/password.component';
import { Blocked } from './components/auth/blocked.component';
import { RouterListener } from './components/router-listener.component';


import styles from './auth-modal.module.scss';

export const AuthModal = () => {
  const { locale } = useRouter();
  const closeModal = useCloseAuthModal();
  const { t } = useTranslation();
  const [router, setCurrentRouter] = useState<{
    location: ReturnType<typeof useLocation>,
    nav: NavigateFunction
  }>();
  const setModalType = useOpenAuthModal();

  const schema = useMemo(() => yup
    .object({
      rememberMe: yup.boolean(),
      authType: yup.mixed<string>().oneOf(['email', 'phone']),
      phone_number: yup.string().when('authType', {
        is: 'phone',
        then: () => yup.string()
          .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.phoneNumber') }))
          .test('valid-phone', t('forms:validationErrors.invalidPhone'), (value) => {
            const phoneNumber = parsePhoneNumberFromString(value);
            return phoneNumber ? phoneNumber.isValid() : false;
          }),
      }),
      email: yup.string().when('authType', {
        is: 'email',
        then: () => yup.string()
          .email(t('forms:validationErrors.invalidEmail'))
          .required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.email') }))
          .matches(/^[^\s@]+@[^\s@]+\.[^\s@]+$/, t('forms:validationErrors.invalidEmail')),
      }),
      password: yup.string().required(t('forms:validationErrors.isNotEmpty', { nameField: t('auth:label.password') })),
    }), [t]);


  const formMethods = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      authType: 'email',
      email: '',
      password: '',
      phone_number: '',
      rememberMe: true,
    },
  });

  const {
    handleSubmit,
    setError,
  } = formMethods;

  const {
    login,
    isAuthenticated,
    loginError,
    resetLoginError,
  } = useAuthContext();
  const { data: me } = useMeQuery(isAuthenticated);

  useEffect(() => {
    if (isAuthenticated && me) {
      if (me.phone_number && !me.email) {
        toast.info(
          <p dangerouslySetInnerHTML={{ __html: t('auth:addEmail') }} className={styles.toast} />,
          { autoClose: false },
        );
      }
      closeModal();
    }
  }, [closeModal, isAuthenticated, me]);

  useEffect(() => {
    if (!loginError || !loginError?.response) return;

    const codes = loginError.response.data?.validation_errors?.code || [];

    if (loginError.response.data?.code === 'ACCOUNT_BLOCKED') {
      router?.nav('/blocked');
    } else if (codes.includes('ACCOUNT_NOT_VERIFIED')) {
      toast(t('auth:login.errors.accountNotVerified'), { type: 'error' });
    } else if (loginError.response.status === 401) {
      setError('password', {
        message: i18n.auth.login.errors.incorrect[locale],
      });
    } else {
      toast(t('auth:error'), { type: 'error' });
    }
  }, [locale, loginError, resetLoginError, setError, setModalType, t]);

  useEffect(() => () => resetLoginError(), []);

  const onSubmit = async (data: yup.InferType<typeof schema>) => {
    if (data.authType === 'phone' && data.phone_number) {
      login({
        phone_number: data.phone_number,
        password: data.password,
        rememberMe: data.rememberMe ?? false,
      });
    } else if (data.authType === 'email' && data.email) {
      login({
        email: data.email,
        password: data.password,
        rememberMe: data.rememberMe ?? false,
      });
    }
  };

  if (isAuthenticated) {
    return null;
  }

  return (
    <Modal
      fullHeight
      showBackButton={router?.location.pathname !== '/'}
      onClickBack={() => router?.nav?.('/')}
      title={<img src="/assets/icons/logo-main-medium.svg" alt="Logo Bazaar" />}
      open
      onClose={closeModal}
    >
      <FormProvider {...formMethods}>
        <form className={styles.content} onSubmit={handleSubmit(onSubmit)}>
          <MemoryRouter initialEntries={['/']}>
            <RouterListener onRouteChange={setCurrentRouter} />
            <Routes>
              <Route path="/" element={<Login />} />
              <Route path="/password" element={<Password />} />
              <Route path="/blocked" element={<Blocked />} />
            </Routes>
          </MemoryRouter>
        </form>
      </FormProvider>
    </Modal>
  );
};
