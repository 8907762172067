import { Dispatch, SetStateAction } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { toast } from 'react-toastify';
import useTranslation from 'next-translate/useTranslation';
import { EventsApi } from '../api';
import { EVENT_KEY } from '../consts';

interface Props {
  setEventResponse: Dispatch<SetStateAction<{ id: number, title: string } | undefined>>
}

export const useCreateEventMutation = ({ setEventResponse }: Props) => {
  const client = useQueryClient();
  const { t } = useTranslation('events');

  return useMutation({
    mutationFn: EventsApi.create,
    onSuccess: async (data) => {
      setEventResponse({ id: data.data.id, title: data.data.title });

      await client.invalidateQueries({
        queryKey: [EVENT_KEY],
      });
    },
    onError: () => toast.error(t('createEvents.error')),
  });
};
