import { useCallback, useEffect, useState } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import useTranslation from 'next-translate/useTranslation';
import parsePhoneNumberFromString from 'libphonenumber-js';
import { AxiosError } from 'axios';
import { useMeContext } from '@/modules/me';
import { useGetVerificationCode, useVerifyPhoneNumber } from '@/modules/me/mutations';
import s from './creation-contacts.module.scss';
import { InputPhone } from '../inputs/input-phone';
import { Button } from '../button';
import { InputText } from '../inputs';

interface PhoneContactProps {
  isEventCreation?: boolean;
  setIsAdPhoneVerified?: React.Dispatch<React.SetStateAction<boolean>>;
}
export const PhoneContact = ({ setIsAdPhoneVerified, isEventCreation }: PhoneContactProps) => {
  const {
    control, register, setError, formState: { errors }, clearErrors, setValue,
  } = useFormContext<{ phone_number: string, code?: string }>();
  const { t } = useTranslation();
  const { me } = useMeContext();
  const phoneNumber = useWatch({ control, name: 'phone_number' });
  const code = useWatch({ control, name: 'code' });
  const [isGetCodeButtonVisible, setIsGetCodeButtonVisible] = useState(false);
  const [isCodeReceived, setIsCodeReceived] = useState(false);
  const {
    mutate: codeMutate, isSuccess: codeIsSuccess, error: codeError, isError: codeIsError,
  } = useGetVerificationCode();
  const {
    mutate: verify, isSuccess: isNewPhoneVerified, error, isError,
  } = useVerifyPhoneNumber();
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  useEffect(() => {
    setValue('phone_number', me?.phone_number?.toString() || '');
  }, [me?.phone_number]);

  const isNumberValid = parsePhoneNumberFromString(phoneNumber || '')?.isValid();

  useEffect(() => {
    if (phoneNumber
      && isNumberValid
      && phoneNumber !== me?.phone_number) {
      setIsGetCodeButtonVisible(true);
      setError('code', {
        type: 'required',
        message: t('forms:validationErrors.required'),
      });
    } else {
      setIsGetCodeButtonVisible(false);
      setIsCodeReceived(false);
      clearErrors('code');
    }
  }, [isNumberValid, phoneNumber, me?.phone_number]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }
      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
        } else {
          setMinutes(minutes - 1);
          setSeconds(59);
        }
      }
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, [seconds, minutes]);

  const onPhoneBlur = useCallback(
    () => (phoneNumber && !isNumberValid
      ? setError('phone_number', {
        type: 'length',
        message: t('forms:validationErrors.invalidPhone'),
      })
      : clearErrors('phone_number')),
    [isNumberValid, phoneNumber, setError, clearErrors, t],
  );

  const getCode = useCallback(async () => {
    setIsGetCodeButtonVisible(false);
    codeMutate({
      operation_type: isEventCreation ? 'verification' : 'update',
      phone_number: phoneNumber,
    });
  }, [phoneNumber, codeMutate, isEventCreation]);

  const onCodeBlur = useCallback(async () => {
    if (code && code.length === 6) {
      verify({
        operation_type: isEventCreation ? 'verification' : 'update',
        phone_number: phoneNumber,
        totp: code,
        isPostRequest: isEventCreation,
      });
    } else {
      setError('code', {
        type: 'required',
        message: t('forms:validationErrors.required'),
      });
    }
  }, [phoneNumber, code, isEventCreation, setError, t, verify]);

  useEffect(() => {
    if (codeIsError && codeError instanceof AxiosError) {
      const responseErrors = codeError.response?.data?.validation_errors?.code;
      if (responseErrors && responseErrors.includes('PHONE_NUMBER_ALREADY_IN_USE')) {
        setError('phone_number', {
          type: 'phone_linked',
          message: t('forms:validationErrors.phone_already_linked'),
        });
      }
      if (codeError.response?.data?.code === 'THROTTLED') {
        setError('phone_number', {
          type: 'throttled',
          message: t('forms:validationErrors.throttled'),
        });
      }
    }
    if (codeIsSuccess) {
      setMinutes(2);
      setSeconds(0);
      setIsCodeReceived(true);
    }
  }, [codeIsSuccess, codeError, codeIsError, setError, t, isEventCreation]);

  useEffect(() => {
    if (isError && error instanceof AxiosError) {
      const responseErrors = error.response?.data?.validation_errors?.code;
      if (responseErrors.includes('INVALID_PHONE_NUMBER_OR_TOTP_CODE')) {
        setError('code', {
          type: 'incorrect',
          message: t('forms:validationErrors.code_incorrect'),
        });
      }
      if (responseErrors.includes('TOTP_CODE_IS_EXPIRED')) {
        setError('code', {
          type: 'expired',
          message: t('forms:validationErrors.code_expired'),
        });
      }
    }
    if (isNewPhoneVerified) {
      clearErrors('code');
      clearErrors('phone_number');
      setIsCodeReceived(false);
    }
  }, [isNewPhoneVerified, error, isError]);

  useEffect(() => {
    const parsedPhoneNumber = parsePhoneNumberFromString(phoneNumber || '');
    const isValid = parsedPhoneNumber?.isValid() || false;
    const isVerified = me?.is_phone_verified && me?.phone_number === phoneNumber;

    if (setIsAdPhoneVerified) {
      return setIsAdPhoneVerified(isVerified || (isValid && isNewPhoneVerified));
    }
  }, [me, phoneNumber, isNewPhoneVerified, setIsAdPhoneVerified]);

  { /* FIXME: временно добавлено условие */ }
  if (!me?.phone_number) return null;

  return (
    <div className={s.field_inner}>
      <p>
        {t('auth:label.phoneNumber')}
        {!isEventCreation && <span> *</span>}
      </p>
      <div className={s.values}>
        <div className={s.values_wrapper}>
          <Controller
            name="phone_number"
            control={control}
            rules={{ required: !isEventCreation }}
            render={({ field: { onChange, value } }) => (
              <InputPhone
                placeholder="+66"
                onChange={(e) => {
                  setIsCodeReceived(false);
                  onChange(e);
                }}
                onBlur={onPhoneBlur}
                value={value}
                international
                defaultValue={me?.phone_number || ''}
                // FIXME: временно закомментировано
                readOnly/* ={me?.is_phone_verified && !isEventCreation} */
              />
            )}
          />
          {errors.phone_number && (
            <p className={s.error}>{errors.phone_number.message}</p>
          )}
        </div>
        {isGetCodeButtonVisible && (
          <div className={s.values_wrapper}>
            <Button
              appearance="primary"
              buttonColor="grey"
              buttonSize="s"
              className={s.get_sms_button}
              onClick={getCode}
            >
              {t('events:createEvents.get_sms')}
            </Button>
          </div>
        )}
        {isCodeReceived && (
          <div className={s.values_wrapper}>
            <p className={s.code_header}>{t('events:createEvents.code')}</p>
            <InputText
              minLength={6}
              maxLength={6}
              placeholder="000000"
              className={s.code_input}
              {...register('code', {
                onBlur: onCodeBlur,
              })}
            />
            {errors.code && (
              <p className={s.error}>{errors.code.message}</p>
            )}
            {seconds || minutes
              ? (
                <p className={s.timer}>
                  {`${t('events:createEvents.resend')}${`${minutes}`.padStart(2, '0')}:${`${seconds}`.padStart(2, '0')}`}
                </p>
              ) : (
                <Button
                  appearance="secondary"
                  buttonColor="green"
                  buttonSize="xs"
                  className={s.get_new_code_button}
                  onClick={getCode}
                >
                  {t('events:createEvents.get_new_code')}
                </Button>
              )}
          </div>
        )}
      </div>
    </div>
  );
};
