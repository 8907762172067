import {
  Controller, useFormContext,
} from 'react-hook-form';
import { memo, useState } from 'react';
import useTranslation from 'next-translate/useTranslation';

import {
  Button, InputPassword, Checkbox, AppTooltip, TipModal,
} from '@/modules/shared/components';
import { useMatchMedia } from '@/modules/shared/hooks';

import { useOpenAuthModal } from '../../../../hooks';
import { useAuthContext } from '../../../../contexts';

import styles from '../../auth-modal.module.scss';


export const Password = memo(() => {
  const { control, getValues } = useFormContext<{
    password: string,
    rememberMe: boolean,
    authType: 'email' | 'phone',
    phone_number?: string,
    email?: string,
  }>();
  const setModalType = useOpenAuthModal();
  const { t } = useTranslation();
  const authType = getValues('authType');
  const email = getValues('email');
  const phone = getValues('phone_number');
  const { isDesktop } = useMatchMedia();
  const [isTipModalOpen, setIsTipModalOpen] = useState(false);

  const {
    isLoading,
  } = useAuthContext();

  const toggleTipModal = () => setIsTipModalOpen((prev) => !prev);

  return (
    <>
      <div>
        <h2 className={styles.title}>{t('auth:titlePassword')}</h2>
      </div>
      <div className={styles.password}>
        {/* FIXME: временно добавлено условие, убрать authType === 'email', весь блок после ":" и TipModal */}
        {authType === 'email' ? (
          <div
            onClick={(e) => {
              e.preventDefault();
              setModalType('forgot', authType === 'email' ? {
                email,
              } : {
                phone,
              });
            }}
            className={styles.passwordForgot}
          >
            {t('auth:forgotPassword.title')}
          </div>
        ) : (
          isDesktop ? (
            <AppTooltip
              title={t('auth:forgotPassword.tooltip')}
              stylesTooltip="gray"
            >
              <span className={styles.passwordForgot} style={{ cursor: 'default' }}>
                {t('auth:forgotPassword.title')}
              </span>
            </AppTooltip>
          ) : (
            <Button
              appearance="secondary"
              buttonColor="blue"
              buttonSize="xs"
              onClick={toggleTipModal}
            >
              {t('auth:forgotPassword.title')}
            </Button>
          )
        )}
        <Controller
          control={control}
          name="password"
          render={({ field, formState: { errors } }) => (
            <>
              <InputPassword
                {...field}
                error={!!errors.password}
                fullWidth
                placeholder={t('auth:label.password')}
              />
              {errors.password && <div className={styles.error}>{errors.password.message}</div>}
            </>
          )}
        />
      </div>
      <div className={styles.rememberMe}>
        <label>
          <Controller
            control={control}
            name="rememberMe"
            render={({ field }) => (
              <Checkbox onChange={field.onChange} checked={!!field.value} checkboxSize="24" />
            )}
          />
          {t('auth:label.rememberPassword')}
        </label>
      </div>
      <div>
        <Button
          disabled={isLoading}
          type="submit"
          fullWidth
          appearance="primary"
          buttonColor="green"
          buttonSize="s"
        >
          {t('auth:login.button')}
        </Button>
      </div>

      <TipModal
        isOpen={isTipModalOpen}
        content={t('auth:forgotPassword.tooltip')}
        onClose={toggleTipModal}
      />
    </>
  );
});
