import {
  Dispatch,
  ReactNode,
  SetStateAction,
  useEffect,
} from 'react';
import clsx from 'clsx';
import useTranslation from 'next-translate/useTranslation';
import { Button } from '../button';
import { useMatchMedia } from '../../hooks';
import s from './success-page.module.scss';

type SuccessPageProps = {
  setIsSent?: Dispatch<SetStateAction<boolean>>;
  onClick?: () => void;
  header: string;
  infoText: string;
  linkToAccount: string;
  linkToAccountText: string;
  refetch?: () => void;
  buttonSlot?: ReactNode;
  className?: string;
};

export const SuccessPage = ({
  setIsSent, header, infoText, linkToAccount, linkToAccountText, refetch, onClick, buttonSlot, className,
}: SuccessPageProps) => {
  const { t } = useTranslation();
  const { isDesktop, isMobile } = useMatchMedia();

  // refetch вызывается, чтобы при переходе в личный кабинет обновлялся список своих мероприятий/объявлений
  useEffect(() => {
    if (refetch) refetch();
  }, []);

  const handleClick = () => {
    if (onClick) onClick();
    if (setIsSent) setIsSent(false);
  };

  return (
    <section className={clsx(s.success, className)}>
      <div className={s.success_content}>
        <div className={s.text}>
          <h1>{header}</h1>
          <p>{infoText}</p>
        </div>
        {!isDesktop && (
          <div className={s.image_wrapper}>
            <img
              src="/assets/images/rabbit-success.svg"
              alt="Image success"
              loading="lazy"
            />
          </div>
        )}
        <div className={s.buttons}>
          {buttonSlot || (
            <Button
              appearance="primary"
              buttonSize="m"
              buttonColor="green"
              onClick={handleClick}
              className={s.button_new}
              fullWidth={isMobile}
            >
              {t('common:createNew')}
            </Button>
          )}
          <Button
            appearance="secondary"
            buttonSize="m"
            buttonColor="green"
            as="link"
            href={linkToAccount}
            className={s.button_link}
          >
            {linkToAccountText}
          </Button>
        </div>
      </div>
      {isDesktop && (
        <div className={s.image_wrapper}>
          <img
            src="/assets/images/rabbit-success.svg"
            alt="Image success"
            loading="lazy"
          />
        </div>
      )}
    </section>
  );
};
