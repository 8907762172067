import {
  Controller, useFormContext,
} from 'react-hook-form';
import {
  Button, ButtonSwitcher, InputText, InputPhone,
} from '@/modules/shared/components';
import useTranslation from 'next-translate/useTranslation';
import { memo } from 'react';
import styles from '../../auth-modal.module.scss';

export const Login = memo(() => {
  const {
    control, watch, formState: { errors, isSubmitting },
  } = useFormContext<{
    authType: string,
    email: string,
    phone: string,
  }>();
  const authType = watch('authType');
  const { t } = useTranslation();

  return (
    <>
      <div className={styles.title}>
        <h2>{t('auth:register.title')}</h2>
        {/* FIXME: временно закомментировано здесь и ниже */}
        {/* <div className={styles.subTitle}>{t('auth:register.subTitle')}</div> */}
      </div>
      <div>
        <Controller
          control={control}
          name="authType"
          render={({ field: { value, onChange } }) => (
            <ButtonSwitcher
              buttonSize="l"
              kind="outline"
              value={value}
              onChange={onChange}
              buttons={[
                {
                  name: t('auth:label.email'),
                  value: 'email',
                },
                /* {
                  name: t('auth:label.phoneNumber'),
                  value: 'phone',
                }, */
              ]}
            />
          )}
        />
      </div>
      {authType === 'email' ? (
        <div>
          <Controller
            key="email"
            control={control}
            name="email"
            render={({ field }) => (
              <InputText
                {...field}
                key="email"
                error={!!errors.email}
                fullWidth
                placeholder={t('auth:label.email')}
              />
            )}
          />
          {errors.email && <div className={styles.error}>{errors.email.message}</div>}
        </div>
      ) : (
        <div>
          <Controller
            control={control}
            name="phone"
            render={({ field }) => (
              <InputPhone
                error={!!errors.phone}
                value={field.value}
                onChange={(v) => field.onChange(v || '')}
                international
                fullWidth
              />
            )}
          />
          {errors.phone && <div className={styles.error}>{errors.phone.message}</div>}
        </div>
      )}
      <div>
        <Button
          disabled={isSubmitting}
          type="submit"
          fullWidth
          appearance="primary"
          buttonColor="green"
          buttonSize="s"
        >
          {t('auth:register.continue')}
        </Button>
      </div>
    </>
  );
});
