import * as yup from 'yup';
import useTranslation from 'next-translate/useTranslation';
import { CreateAdTypes } from '../types';
import { useSchemaHelpers } from './use-schema-helpers.hook';
import {
  AUTO_ENGINE_MAX, AUTO_ENGINE_MIN,
  MOTO_ENGINE_MAX,
  MOTO_ENGINE_MIN,
} from '../const';

const priceTypes: CreateAdTypes['price_type'][] = ['for_money', 'for_free', 'by_agreement'];
const operationTypes: CreateAdTypes['operation_type'][] = ['buy', 'rent'];
// const contactTypes: CreateAdTypes['contact'][] = ['all', 'calls_only', 'messages_only'];

export const useCreateAdSchema = (category: string | undefined) => {
  const { t } = useTranslation();
  const {
    allTheCategoryRequiredString,
    allTheSubcategoryRequiredString,
    requiredFieldWithOperationType,
  } = useSchemaHelpers();

  const baseSchema = {
    category: yup.number().required(),
    sub_category: yup.number().required(),
    under_sub_category: yup.number().notRequired(),
    operation_type: yup.string().oneOf(operationTypes).notRequired(),

    city: yup.number().required(),
    district: yup.number()
      .transform((value, originalValue) => ((originalValue) === '' ? undefined : value))
      .when(['category'], {
        // eslint-disable-next-line @typescript-eslint/no-shadow
        is: (category: number) => ![10, 13].includes(category),
        then: (schema) => schema.required(t('forms:validationErrors.FIELD_REQUIRED')),
        otherwise: (schema) => schema.notRequired(),
      }), // 10 - услуги, 13 - excursions
    address: yup.string().notRequired(),
    // FIXME: временно закомментировано .required()
    phone_number: yup.string()/* .required() */,
    // закомментировано временно
    // contact: yup
    //   .string()
    //   .oneOf(contactTypes)
    //   .required(),

    title: yup
      .string()
      .required(t(
        'forms:validationErrors.isNotEmpty',
        { nameField: t('createAd:descriptionAndPhotos.adTitle') },
      ))
      .max(32, t('forms:validationErrors.maxLength', { maxLength: 32 })),

    description: yup
      .string()
      .required(t(
        'forms:validationErrors.isNotEmpty',
        { nameField: t('createAd:descriptionAndPhotos.description') },
      ))
      .max(10000, t('forms:validationErrors.maxLength', { maxLength: 10000 })),

    price: yup
      .string()
      .when('price_type', {
        is: (value: CreateAdTypes['price_type']) => value === 'for_money',
        then: (schema) => schema.required(t('createAd:errors.price')),
        otherwise: (schema) => schema.notRequired(),
      }),

    price_type: yup
      .string()
      .oneOf(priceTypes)
      .required(t('createAd:errors.price')),

    images: yup.array().default([]),
  };

  let dynamicSchema = {};


  switch (category) {
    case 'real-estate':
      dynamicSchema = {
        state: yup.string()
          .when(['sub_category', 'operation_type'], {
            is: (sub_category: number, operation_type: string) => (
              (sub_category === 125 && operation_type === 'buy') || sub_category === 126
            ),
            then: (schema) => (
              schema.required(t('forms:validationErrors.FIELD_REQUIRED'))
            ),
            otherwise: (schema) => schema.notRequired(),
          }),
        duration: requiredFieldWithOperationType('sub_category', 125, 'rent'),
        number_of_rooms: allTheSubcategoryRequiredString(125),
        living_space: yup.string(),
        land_area: yup.number()
          .transform((value, originalValue) => (
            originalValue === '' ? undefined : value
          )),
        completion_status: yup.string()
          .when(['sub_category', 'operation_type'], {
            is: (sub_category: number, operation_type: string) => (
              (sub_category === 125 || sub_category === 126) && operation_type === 'buy'
            ),
            then: (schema) => (
              schema.required(t('forms:validationErrors.FIELD_REQUIRED'))
            ),
            otherwise: (schema) => schema.notRequired(),
          }),
        commercial_area: yup.number()
          .transform((value, originalValue) => ((originalValue) === '' ? undefined : value)),
        permitted_construction: allTheSubcategoryRequiredString(127),
      };
      /**
      * категория: 2 - недвижимость
      * подкатегории: 125 - жилая, 126 - коммерческая, 127 - земельный участок
      * подподкатегории: 394 - дом, 395 - вилла
      * */
      break;

    case 'transport':
      dynamicSchema = {
        condition: requiredFieldWithOperationType('category', 1, 'buy'),
        duration: requiredFieldWithOperationType('category', 1, 'rent'),
        brand: allTheSubcategoryRequiredString([1, 2, 101]),
        year: yup.number()
          .transform((value, originalValue) => (
            originalValue === '' ? null : value
          ))
          .when(['sub_category', 'operation_type'], {
            is: (sub_category: number, operation_type: string) => (
              (sub_category === 1 || sub_category === 2 || sub_category === 101) && operation_type === 'buy'
            ),
            then: (schema) => schema
              .required(t('forms:validationErrors.FIELD_REQUIRED'))
              .max(
                new Date().getFullYear(),
                t('forms:validationErrors.INVALID_YEAR', { year: new Date().getFullYear() }),
              ),
            otherwise: (schema) => schema.notRequired(),
          }),
        mileage: yup.number()
          .transform((value, originalValue) => (
            originalValue === '' ? null : value
          ))
          .when(['sub_category', 'operation_type'], {
            is: (sub_category: number, operation_type: string) => (
              (sub_category === 1 || sub_category === 2 || sub_category === 101) && operation_type === 'buy'
            ),
            then: (schema) => schema
              .required(t('forms:validationErrors.FIELD_REQUIRED'))
              .max(500_000, t('forms:validationErrors.min_max', { min: 0, max: 500_000 })),
            otherwise: (schema) => schema.notRequired(),
          }),
        engine_type: allTheSubcategoryRequiredString([1, 2, 101]),
        transmission: allTheSubcategoryRequiredString([1, 2, 101]),
        engine_capacity: yup
          .number()
          .transform((value, originalValue) => (
            originalValue === '' ? null : value
          ))
          .when('sub_category', {
            is: (value: number) => value === 1,
            then: (createAdSchema) => createAdSchema
              .min(AUTO_ENGINE_MIN, t('forms:validationErrors.min_max', {
                min: AUTO_ENGINE_MIN, max: AUTO_ENGINE_MAX,
              }))
              .max(AUTO_ENGINE_MAX, t('forms:validationErrors.min_max', {
                min: AUTO_ENGINE_MIN, max: AUTO_ENGINE_MAX,
              })),
            otherwise: (createAdSchema) => createAdSchema
              .min(MOTO_ENGINE_MIN, t('forms:validationErrors.min_max', {
                min: MOTO_ENGINE_MIN, max: MOTO_ENGINE_MAX,
              }))
              .max(MOTO_ENGINE_MAX, t('forms:validationErrors.min_max', {
                min: MOTO_ENGINE_MIN, max: MOTO_ENGINE_MAX,
              })),
          })
          .when('sub_category', {
            is: (sub_category: number) => (sub_category === 1 || sub_category === 2 || sub_category === 101),
            then: (schema) => schema.required(t('forms:validationErrors.FIELD_REQUIRED')),
            otherwise: (schema) => schema.notRequired(),
          }),
      };
      /**
       * категория: 1 - транспорт
       * подкатегории: 1 - автомобили, 2 - мотоциклы, 101 - скутеры
       * */
      break;

    case 'personal-items':
      dynamicSchema = {
        state: allTheCategoryRequiredString(3),
        size: allTheSubcategoryRequiredString([106, 107, 109, 110, 10]),
        brand: allTheSubcategoryRequiredString([106, 107, 109, 110, 10, 119]),
        gender: allTheSubcategoryRequiredString(119),
      };
      /**
       * категория: 3 - личные вещи
       * подкатегории:
       * 109 - мужская одежда, 110 - мужская обувь, 10 - детская одежда,
       * 106 - женская обувь, 107 - женская одежда, 119 - аксессуары
      * */
      break;

    case 'services':
      dynamicSchema = {
        availability_of_loaders: yup
          .boolean()
          .oneOf([true, false])
          .when('sub_category', {
            is: (sub_category: number) => sub_category === 58, // 58 - грузоперевозки
            then: (schema) => schema.required(t('forms:validationErrors.FIELD_REQUIRED')),
            otherwise: (schema) => schema.notRequired(),
          }),
      };
      break;

    default:
      break;
  }

  const schema = yup.object().shape({
    ...baseSchema,
    ...dynamicSchema,
  });

  return { schema };
};
